<template>
  <div>
    <data-table
      v-if="isQueryDone"
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="index" v-for="(record, index) in responseData.records">
          <vs-td>
            <vx-tooltip v-if="canEdit" text="Edit" class="flex justify-center">
              <vs-button
                color="primary"
                type="line"
                icon-pack="feather"
                icon="icon-edit"
                @click="edit(record.invoiceExchange.ID)"
              />
            </vx-tooltip>
            <vx-tooltip
              v-if="canCancel"
              text="Cancel"
              class="flex justify-center"
            >
              <vs-button
                color="danger"
                type="line"
                icon-pack="feather"
                icon="icon-x"
                @click="handleOpenPropmt(record)"
              />
            </vx-tooltip>
            <p v-else>No Action</p>
          </vs-td>
          <vs-td>{{ record.invoiceExchange.code }}</vs-td>
          <vs-td>{{ record.invoiceExchange.invoice_exchange_number }}</vs-td>
          <vs-td>{{ dateFormat(record.invoiceExchange.date) }}</vs-td>
          <vs-td>{{
            getTerritoryName(record.invoiceExchange.territory_id)
          }}</vs-td>
          <vs-td>
            Code : {{ record.invoiceExchange.client_code }} <br />
            Name : {{ record.invoiceExchange.client_name }}
          </vs-td>
          <vs-td>
            Code : {{ record.invoiceExchange.client_code }} <br />
            Name :{{ record.customer_address.contact_name }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
    <vs-prompt
      @cancel="handleClosePrompt"
      @accept="cancelInvoiceExchange"
      @close="handleClosePrompt"
      :active.sync="activePrompt"
      title="Cancel Invoice Exchange"
      color="danger"
    >
      <div class="con-exemple-prompt">
        Are you sure want to cancel invoice exchange <br />
        <span class="font-bold">{{
          selectedData.invoiceExchange
            ? selectedData.invoiceExchange.invoice_exchange_number
            : ""
        }}</span
        >?
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    territoryIDs: Array,
    selectedCustomer: Object,
    selectedDate: Object,
    invoiceExchangeStatus: Number,
    isQueryDone: Boolean,
    isFilter: Boolean,
  },
  watch: {
    isFilter() {
      if (this.isFilter) {
        this.reloadData(this.params);
      }
    },
    // territoryIDs() {
    //   this.reloadData(this.params);
    // },

    // selectedCustomer() {
    //   this.reloadData(this.params);
    // },

    // selectedDate() {
    //   console.log("selectedDate changed", this.selectedDate);
    //   this.reloadData(this.params);
    // },
  },
  computed: {
    canEdit() {
      return this.$store.getters["user/hasPermissions"]("edit");
    },
    canCancel() {
      return this.$store.getters["user/hasPermissions"]("cancel");
    },
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "invoice_exchanges.id",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Code",
          sortable: false,
        },
        {
          text: "Nomor Tukar Faktur",
        },
        {
          text: "Tanggal Tukar Faktur",
        },
        {
          text: "Territory",
          value: "code",
          // width: '5%'
        },
        {
          text: "Sold To",
          sortable: false,
        },
        {
          text: "Ship To",
        },
      ],
      responseData: {},
      checkedAll: false,
      checked: [],
      dataChecked: [],
      selectedData: {},
      activePrompt: false,
    };
  },
  methods: {
    dateFormat(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    edit(id) {
      this.$router.push({ path: "invoice-exchange/edit/" + btoa(id) });
    },
    handleOpenPropmt(data) {
      this.selectedData = data;
      this.activePrompt = true;
    },
    handleClosePrompt() {
      this.activePrompt = false;
      this.selectedData = {};
    },
    getTerritoryName(id) {
      if (id === 0) {
        return "";
      }
      const territories = JSON.parse(
        JSON.stringify(this.$store.state.operatingUnit.filter.territory)
      );

      const territory = territories.find(
        (territory) => territory.TerritoryID === id
      );

      return territory.TerritoryName
        ? `${territory.TerritoryCode} - ${territory.TerritoryName}`
        : "";
    },
    cancelInvoiceExchange() {
      this.$vs.loading();
      this.$http
        .patch(
          `/api/v1/invoice-exchange/status/${this.selectedData.invoiceExchange.ID}`,
          {
            status: 1, //cancel
          }
        )
        .then((r) => {
          if (r.code > 299) {
            this.$vs.loading.close();
            this.$vs.notify({
              position: "top-right",
              title: "Cancel Invoice Exchange",
              text: r.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
            });
          } else if (r.code == 200) {
            this.$vs.notify({
              position: "top-right",
              title: "Cancel Invoice Exchange",
              text: "Cancel invoice exchange success",
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
            });
            // this.$emit("reloadDataFromChild");
            this.$vs.loading.close();
            this.reloadData(this.params);
          } else {
            this.$vs.loading.close();
          }
          this.handleClosePrompt();
        });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      // console.log(
      //   "getters",
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"]
      // );
      // const territory_ids =
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice-exchange/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_ids: this.territoryIDs,
            status: 0,
            client_name:
              !this.selectedCustomer.name || this.selectedCustomer.name == "All"
                ? undefined
                : this.selectedCustomer.name,
            client_code:
              !this.selectedCustomer.code || this.selectedCustomer.code == "All"
                ? undefined
                : this.selectedCustomer.code,
            start_date: !this.selectedDate.startDate
              ? undefined
              : this.dateFormat(this.selectedDate.startDate),
            end_date: !this.selectedDate.endDate
              ? undefined
              : this.dateFormat(this.selectedDate.endDate),
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
